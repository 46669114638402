import * as React from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { imageUrl } from "../../../../services/Constants";
import * as RequestLogActions from "../../../../actions/request_log.action";
import * as menuconfigActions from "../../../../actions/menuconfig.action";
import * as requestDeleteActions from "../../../../actions/request.delete.actions";
import * as requestActions from "../../../../actions/request.action";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../../reducers";
import Autocomplete from '@mui/material/Autocomplete';
import * as userActions from "../../../../actions/user.action";
import { server, TOKEN,MEMBER_ID,reqeuestMassageFileUrl,reqeuestMassageImageUrl } from "../../../../services/Constants";
import {
    Stack,
    IconButton,
    Typography,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    CardContent,
    Card,
    TextField
} from "@mui/material";
import { Link, useNavigate,useMatch } from "react-router-dom";
import { useAppDispatch } from "../../../..";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { blue, red } from "@mui/material/colors";
import "./RequestlogsDetails.css";
import { Search as SearchIcon } from "@mui/icons-material";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import * as loginActions from "../../../../actions/login.action";
// import SearchBar from "material-ui-search-bar";
// const CustomToolbar = (props:any) => {
//     return (
//         <Box>
//             <Grid>
//                 <SearchBar {...props} />
//             </Grid>
//         </Box>
//     )
// }



interface QuickSearchToolbarProps {
    clearSearch: () => void;
    onChange: () => void;
    value: string;
}


type RequestlogsDetailsProps = {
  //
};

const RequestlogsDetails: React.FC<any> = () => {
  const requesReducer = useSelector((state: RootReducers) => state.requesReducer);
  const RequestLogStateReducer = useSelector((state: RootReducers) => state.RequestLogStateReducer);
  const menuconfigReducer = useSelector((state: RootReducers) => state.menuconfigStateReducer);
  const UserStateReducer = useSelector((state: RootReducers) => state.userStateReducer);
  const match = useMatch("request-logs-details/:id");
  const req_id = match?.params.id;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedRequestlogsDetails, setSelectedRequestlogsDetails] = React.useState<any>({});
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [dataAddRequestlogsDetails, setdataAddRequestlogsDetails] = React.useState({menu_name:"",menu_route:""});
  const [dataEditRequestlogsDetails, setdataEditRequestlogsDetails] = React.useState({id:0,menu_name:"",menu_route:""});
  
  const [openaddRequestlogsDetails, setOpenaddRequestlogsDetails] = React.useState(false);
  const [openeditRequestlogsDetails, setOpeneditRequestlogsDetails] = React.useState(false);

  const user_memberlist = [{user_type: "User"},{user_type: "Member"}];
  const [dataSearch, setdataSearch] = React.useState({
    user_id:0,
    user_type:"User"
  });

  const [dataAlluserandmember, setdataAlluserandmember] = React.useState<any[]>([]);

  const Alllanguages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'Thai'
    }
  ];
  let [log_list, setLogList] = React.useState<any[]>([]);
  const [dialogOpenBox, setDialogOpenBox] = React.useState(false);
    const [imageNameShowBox, setimageNameShowBox] = React.useState(String);
    const handleOpenDialogBox = (imageName:string) => {
        setimageNameShowBox(imageName);
        setDialogOpenBox(true);
    }

  //load data table
  React.useEffect(() => {
    if (!localStorage.getItem(TOKEN)) {
      dispatch(loginActions.logouttologinpage(navigate));
    }
    // dispatch(menuconfigActions.getMENU_CONFIGCheckRouteConfigmenu(window.location.pathname.replace("/", "").split("/")[0],navigate));
        dispatch(RequestLogActions.setRequestLogFetchingToState());
        const callawaitfunction = async () =>{
        // await dispatch(requestActions.loadRequestByIdDetail(Number(req_id)));
        await dispatch(RequestLogActions.loadRequestLogById(Number(req_id)));
        }
        callawaitfunction();
  }, []);


  React.useEffect(() => {
    let  dataList:any = [];
        let index = 0;
        RequestLogStateReducer.result.forEach((item) =>{
            if(Number(item.status) == 1){
                item.status_name = `${t('Draft')}`
            }else if(Number(item.status) == 2){
                item.status_name = `${t(`Not Started`)}`
            }else if(Number(item.status) == 3){
                item.status_name = `${t('In Progress')}`
            }else if(Number(item.status) == 4){
                item.status_name = `${t(`Done`)}`
            }else if(Number(item.status) == 5){
                item.status_name = `${t('Cancel')}`
            }else if(Number(item.status) == 6){
                item.status_name = `${t('Behind')}`
            }else if(Number(item.status) == 7){
                item.status_name = `${t('Blocked')}`
    
            }else if(Number(item.status) == 8){
                item.status_name = `${t('Assign')}`
            }
            if(item.type_table == "assign"){
                dataList[index] = {type:`data`,name_by : item.first_name_by, text: `${t('assigned this ticket to')}`,status: '', name_to: item.first_name_to}
            }else if(item.type_table == "log"){
                if(item.status_name == 'Cancel'){
                    dataList[index] = {type:`data`,name_by : item.first_name_by, text: `${t('cancelled this ticket')}`,status: '', name_to: item.first_name_to}    
                }else if(item.status_name == 'Done'){
                    dataList[index] = {type:`data`,name_by : item.first_name_by, text: `${t('closed this ticket')}`,status: '', name_to: item.first_name_to}
                }else{
                    dataList[index] = {type:`data`,name_by : item.first_name_by, text: `${t('changed status to')}`,status: item.status_name, name_to: item.first_name_to}
                }
            }else if(item.type_table =="message"){
                if(item.file_name != ''){
                    if(item.image_name != ''){
                        let file_show = item.file_name.split(",")
                        for(let i=0;i < file_show.length;i++){
                            dataList[index] = {type:`file_name`,name_by : item.first_name_by, text: `${t('attached')}`,file:file_show[i],text2:`${t('to this ticket')}`}
                            index += 1;
                        }
                        let image_show = item.image_name.split(",")
                        for(let i=0;i < image_show.length;i++){
                            dataList[index] = {type:`image_name`,name_by : item.first_name_by, text: `${t('attached')}`,file:image_show[i],text2:`${t('to this ticket')}`}
                            index += 1;
                        }
                        
                    }else{
                        let file_show = item.file_name.split(",")
                        for(let i=0;i < file_show.length;i++){
                            dataList[index] = {type:`file_name`,name_by : item.first_name_by, text: `${t('attached')}`,file:file_show[i],text2:`${t('to this ticket')}`}
                            index += 1;
                        }
                    }

                }else{
                    if(item.image_name != ''){
                        let image_show = item.image_name.split(",")
                        for(let i=0;i < image_show.length;i++){
                            dataList[index] = {type:`image_name`,name_by : item.first_name_by, text: `${t('attached')}`,file:image_show[i],text2:`${t('to this ticket')}`}
                            index += 1;
                        }
                    }
                    // else{
                    //     dataList[index].text = `${item.first_name_by} send ${item.description} to this ticket`;
                    // }
                }
            }else{
                dataList[index] = { name : item.first_name_by, text: "",status: '',name_to: item.first_name_to };
            }
            index += 1;
        })

        setLogList(dataList)
}, [RequestLogStateReducer.result]);


// React.useEffect(() => {
//     let  dataList:any[] = [];
//     let index = 0;
//     console.log("requesReducer.result")
//     console.log(requesReducer.result)
// }, [requesReducer.result]);

React.useEffect(() => {
  setdataAlluserandmember(UserStateReducer.result);
}, [UserStateReducer.result]);





  const requestColumns: GridColDef[] = [
      {
          headerName: `${t('ID')}`,
          field: "id",
          flex: 1,
          minWidth: 30 
      },
      {
          headerName: `${t("Name")}`,
          field: `.`,
          flex: 1,
          minWidth: 150,
          renderCell: ({ row }) => (
            <>
            {row.tbl_user?.first_name} {row.tbl_user?.last_name} {row.tbl_member?.contact_name}
            </>
          ), 
      },
      {
        headerName: `${t("Login Date")}`,
        field: `login_date`,
        flex: 1,
        minWidth: 150
    },
    {
      headerName: `${t("Logout Date")}`,
      field: `logout_date`,
      flex: 1,
      minWidth: 150
  },
      {
        headerName: `${t("User Type")}`,
        field: "user_type",
        flex: 1,
        minWidth: 150 
    },
  ];

  //search
  const [searchText, setSearchText] = React.useState("");
  const [searchText1, setSearchText1] = React.useState("");
  const [tableData, setTableData] = React.useState<any[]>([]);




  return (
    <Container fixed sx={{ mt: 3}} >
      <Box 
      sx={{}}
     >
          {/* Summary Icons */}
          <Grid 
          container item
          justifyContent="center"
          alignItems="center"
          sx={{
              margin: 'auto',
              width: '100%',
              maxWidth: '60rem'
          }}
          ><Typography variant="h5" component="h2" >{t(`Request Log Details`)}</Typography>
          </Grid>
          <br />
          <br />
          <Grid 
                        container   justifyContent="center"
                        xs={12}
                    >
                        <Grid 
                            xs={12} sm={12} md={12} lg={12} xl={12} 
                            direction="column" 
                            container item
                            sx={{
                                borderBottom: '#ABA9A6 solid 2px',
                                marginBottom: "1rem"
                            }}
                        >
                            <Typography 
                                gutterBottom 
                                variant="h5" 
                                component="h2" 
                                sx={{
                                    color:'#4F4A41',
                                    textAlign: "left",
                                    marginTop: 2,
                                }}
                            >
                                {t(`Details`)}
                            </Typography>
                        </Grid>
                    </Grid>
          {
                        log_list.map((item, index) => (
                        <>
                            <Grid 
                                container   
                                justifyContent="center"
                                xs={12}
                                sx={{
                                    marginBottom: "10px"
                                }}
                            >
                                <Grid 
                                    xs={12} sm={1} md={1} lg={1} xl={1} 
                                    direction="column" 
                                    container item
                                ></Grid>
                                <Grid 
                                    xs={12} sm={10} md={10} lg={10} xl={10} 
                                    direction="column" 
                                    container item
                                >   
                                    <Grid 
                                        container  
                                        xs={12}
                                    >
                                        <Grid 
                                            xs={12} sm={3} md={3} lg={3} xl={3} 
                                            direction="column" 
                                            container item

                                        >
                                            <Typography sx={{textAlign:'start',fontSize: '15px'}}>
                                                <b>{item.name_by}</b> 
                                            </Typography>
                                        </Grid>
                                        <Grid 
                                            xs={12} sm={9} md={9} lg={9} xl={9} 
                                            direction="column" 
                                            container item

                                        >
                                            {
                                                item.type == 'data' &&
                                                <>
                                                    <Typography sx={{textAlign:'start',fontSize: '15px'}}>
                                                        {item.text} <b>{item.status}</b><b>{item.name_to}</b>
                                                    </Typography> 
                                                </>  
                                            }   
                                            {
                                                item.type == 'file_name' &&
                                                <>
                                                    <Typography sx={{textAlign:'start',fontSize: '15px'}}>
                                                        {item.text} 
                                                        <Button  
                                                            size="small"
                                                            href="#"
                                                            target="_blank"
                                                            onClick={() => {
                                                                let pdf_newTab:any = window.open("","_blank");
                                                                pdf_newTab.document.write(
                                                                "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                                                                    `${reqeuestMassageFileUrl}/${item.file}` +
                                                                "'></iframe></body></html>"
                                                                );
                                                                pdf_newTab.document.close();
                                                            }} 
                                                        >
                                                            {item.file}
                                                        </Button>
                                                        {item.text2}
                                                    </Typography> 
                                                </>  
                                            }   
                                            {
                                                item.type == 'image_name' &&
                                                <>
                                                    <Typography sx={{textAlign:'start',fontSize: '15px'}}>
                                                        {item.text} 
                                                        <Button  
                                                            size="small"
                                                            href="#"
                                                            target="_blank"
                                                            sx={{}}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleOpenDialogBox(item.file);
                                                            }}
                                                        >
                                                            {item.file}
                                                        </Button>
                                                        {item.text2}
                                                    </Typography> 
                                                </>  
                                            }   
                                        </Grid>
                                        <Grid 
                                            xs={12} sm={1} md={1} lg={1} xl={1} 
                                            direction="column" 
                                            container item
                                        > </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                        ))
                    }
      </Box>
      </Container>
);
                }
export default RequestlogsDetails;

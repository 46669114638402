import * as React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { Paper,Grid,Typography } from "@mui/material";
import pdf from '../assets/DCMPrivacyPolicy.pdf';
import Link from '@mui/material/Link';
import { Margin, Padding } from "@mui/icons-material";


const Footer: React.FC<any> = () => {
  const { t,i18n } = useTranslation();
  const currentYear = new Date().getFullYear(); 
  return (
    <Grid style={{ position:'absolute',bottom:'0',backgroundColor:'white',width:'100%',maxWidth:'100%',height:'50px',textAlign:"center" }} container spacing={3}  alignItems="center"
    justifyContent="center" direction="column">
       <Grid  xs={12} sm={12} md={12} lg={12} xl={12} container item spacing={0}  alignItems="center"
    justifyContent="center" direction="column">
       <Typography    
          sx={{fontSize: "16px"}}
                        >
                        {t('Copyright ')} {currentYear} {t('Digital Corporate Management Co. Ltd. All Rights Reserved ')}
                        <Link  style={{ paddingRight: "10px" }}  href={`${pdf}`} target="_blank" rel="noreferrer">{t('Privacy Policy')}</Link>
                        <Link href={`/my-data-rights-form`} target="_blank" rel="noreferrer">{t('Manage My Data Rights')}</Link>
                      </Typography>
    </Grid>

    </Grid>
//     <footer id="footer">
//     <p id="copyright">{t('Copyright')}</p>
//     {/* <p><a href={pdf} target="_blank" rel="noreferrer" id="PPlink">{t('Our Privacy Policy')}</a></p> */}
// </footer>
  );
}

export default Footer;